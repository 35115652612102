@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideIn {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes scaleIn {
  from {
    transform: scale(0.8);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes float {
  0%, 100% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-20px);
  }
}

@keyframes floatDelayed {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-15px);
  }
}

@keyframes rotateSlow {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes glowPulse {
  0% {
    box-shadow: 0 0 5px rgba(139, 92, 246, 0.5);
  }
  50% {
    box-shadow: 0 0 20px rgba(139, 92, 246, 0.8);
  }
  100% {
    box-shadow: 0 0 5px rgba(139, 92, 246, 0.5);
  }
}

@keyframes slideDown {
  from {
    transform: translateY(-10px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Animation Classes */
.animate-fade-in {
  animation: fadeIn 1s ease-out forwards;
}

.animate-fade-in-delay {
  opacity: 0;
  animation: fadeIn 1s ease-out forwards;
  animation-delay: 0.3s;
}

.animate-fade-in-delay-2 {
  opacity: 0;
  animation: fadeIn 1s ease-out forwards;
  animation-delay: 0.6s;
}

.animate-slide-in {
  animation: slideIn 0.8s ease-out forwards;
}

.animate-scale-in {
  animation: scaleIn 0.5s ease-out forwards;
}

.animate-float {
  animation: float 6s ease-in-out infinite;
}

.animate-float-delayed {
  animation: floatDelayed 7s ease-in-out infinite;
  animation-delay: 1s;
}

.animate-float-slow {
  animation: float 8s ease-in-out infinite;
  animation-delay: 2s;
}

.animate-rotate-slow {
  animation: rotateSlow 12s linear infinite;
}

.glow-effect {
  animation: glowPulse 2s infinite;
}

.nav-item-animation {
  animation: slideDown 0.3s ease-out forwards;
}

/* Navbar Styles */
.mobile-menu-backdrop {
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
}

/* Custom Scrollbar */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #1a1a1a;
}

::-webkit-scrollbar-thumb {
  background: #4a4a4a;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #636363;
}

/* Glass Effect */
.glass-effect {
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

/* Gradient Text Effect */
.gradient-text {
  background: linear-gradient(45deg, #c084fc, #db2777);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  display: inline-block;
}

/* Card Hover Effects */
.skill-card {
  transition: all 0.3s ease;
}

.skill-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

/* Form Input Styles */
.form-input {
  transition: all 0.3s ease;
}

.form-input:focus {
  transform: scale(1.02);
}

/* Button Hover Animation */
.button-hover {
  position: relative;
  overflow: hidden;
}

.button-hover::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  transform: translate(-50%, -50%);
  transition: width 0.6s ease, height 0.6s ease;
}

.button-hover:hover::after {
  width: 300%;
  height: 300%;
}

/* Hero Section Specific Styles */
.hero-gradient-text {
  background: linear-gradient(to right, #c084fc, #db2777);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  transition: all 0.3s ease;
}

.hero-gradient-text:hover {
  background: linear-gradient(to right, #db2777, #c084fc);
}

.hero-icon-container {
  @apply relative p-4 bg-gray-800/80 rounded-lg backdrop-blur-sm transition-all duration-300;
}

.hero-icon-container:hover {
  @apply transform scale-110 -translate-y-1;
  box-shadow: 0 10px 25px rgba(139, 92, 246, 0.2);
}

.hero-blob {
  filter: blur(70px);
  opacity: 0.5;
  animation: float 10s ease-in-out infinite;
}

/* Global Settings */
html {
  scroll-behavior: smooth;
}

body {
  @apply bg-black text-white;
}