@keyframes slideUp {
    from { opacity: 0; transform: translateY(20px); }
    to { opacity: 1; transform: translateY(0); }
  }
  
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  .animate-slideUp {
    animation: slideUp 0.5s ease-out forwards;
  }
  
  .animate-fadeIn {
    animation: fadeIn 0.5s ease-out forwards;
  }
  
  .card-enter {
    opacity: 0;
    transform: translateY(20px);
  }
  
  .card-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 500ms, transform 500ms;
  }
  
  .card-exit {
    opacity: 1;
  }
  
  .card-exit-active {
    opacity: 0;
    transform: translateY(-20px);
    transition: opacity 500ms, transform 500ms;
  }